import type { GetStaticPaths, GetStaticProps } from 'next'
import { groq } from 'next-sanity'
import { getClient } from '@sans-souci/services/sanity-service'
import { generateLocalePaths } from '@sans-souci/utils'
import { PageBuilderSection, PageResponse } from '@sans-souci/models'
import { DefaultPage } from '@sans-souci/page'
import { DEFAULT_LOCALE_ID, REVALIDATE_PAGE } from '@sans-souci/configs'
import { getPageProps } from '../helpers/getPageProps'
import { captureMessage } from '@sentry/nextjs'

export const getStaticPaths: GetStaticPaths = async ({
  defaultLocale = DEFAULT_LOCALE_ID,
}) => {
  // TODO consider excluding shopify paths (collections, products)

  const paths: string[] = await getClient(false).fetch(
    groq`*[!(_id in path('drafts.**')) && _type != 'collection' && _type != 'product' && defined(path.current)][].path.current`,
  )

  return {
    paths: generateLocalePaths(paths, defaultLocale),
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps<
  NonNullable<PageResponse<PageBuilderSection[]>>
> = async ({ params, locale = DEFAULT_LOCALE_ID, preview = false }) => {
  if (!params) {
    throw new Error('params not exist')
  }

  const { paths } = params

  if (typeof paths === 'string') {
    throw new Error('paths must be passed as an array. Received string')
  }

  const path = `/${paths ? paths.join('/') : ''}`

  const pageProps = await getPageProps(path, locale, preview)

  if (!pageProps) {
    //TODO: consider sending that info to sentry
    const msg = `Not found page data for "${path}"`
    captureMessage(msg)
    return { notFound: true }
  }

  return {
    props: pageProps,
    revalidate: REVALIDATE_PAGE,
  }
}

export default DefaultPage
